/* .backgroundImg{
    background-image: url("../Images/");
} */
.line{
    height: 1px;
    border-top: 1px solid #ebebeb;
    display: block;
    position: relative;
    top: 1px;
    width: 35px !important;
    border-top-width: 4px;
    margin: 0 auto;
    border-color: #bd9234;
}
.line1{
    height: 1px;
    border-top: 1px solid #ebebeb;
    display: block;
    position: relative;
    top: 1px;
    width: 35px !important;
    border-top-width: 4px;
    margin-left: 560px;
    border-color: #fff;
}
   
.blureness{
    border: solid 1px #000;
    -webkit-filter: blur(3px);

}
placeholder{
    color:#000;
}

/* Video.css */

/* Default styling */
.video-container {
    height: 70%;
  }
  
  .video-background {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire container */
  }
  
  .video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .video-container {
      height: 100%; /* Adjust as needed for smaller screens */
    }
  
    .video-overlay {
      margin-top: -150px; /* Adjust as needed for smaller screens */
    }
  }
  

  .cardback{
    background-image: url('../Images//cardback1.jpeg');
    background-size: 280px; /* or use 'contain' or specify a width and height */
    /* width: 280px; set the width you desire */
    height: 200px;
  }
.card{
  width: 80%; 
    display: inline-block;

}



.amenity-card {
  margin-right: 10px; /* Adjust the margin as per your preference */
}

